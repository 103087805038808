
import { defineComponent, ref, watch, reactive, computed, onMounted } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import TableSkeleton from "@/components/skeleton/Table.vue";
import Upload from "@/components/forms/Upload.vue";
import { createUploaderFunction } from "@/core/helpers/upload";
import { useRouter } from "vue-router";
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import {
  showErrorPopup,
  showSuccessPopup,
} from "@/core/helpers/common-helper";
import to from "await-to-js";
import BaseButton from "@/components/BaseButton.vue";
import useJournalEntry from "@/core/services/compositions/reconcile/useJournalEntry";
import { ElNotification } from 'element-plus';
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import moment from 'moment';
import ApiService from "@/core/services/ApiService";
import FilterJournal from "./filter-journal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ElMessage } from 'element-plus'
import { checkPermission } from '@/router/authentication'
import useDownload from "@/core/helpers/download";
import { numberFormat } from "@/core/helpers/currency-helper";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    Upload,
    BaseButton,
    FilterJournal,
    VueJsonPretty
  },
  setup() {
    // init
    const { t } = useI18n();
    const paginationRef = ref(null);
    const currentTab = ref("first");
    const { query } = useQuery();
    const router = useRouter();
    const isUploading = ref(false);
    const { channel } = useChannelByHash();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const hash = ref(null);
    const remark = ref(null);
    const jsonRespon = ref(null);
    const uploadFile = createUploaderFunction(
      () => `/app/reconcile/journal-entry/upload`, 'attachment', { channel_id: hash }
    );
    const files = ref([]);
    const dialogVisible = ref(false)
    const dialogRemarkVisible = ref(false)
    const { handleDownloadToFile, isDownloading } = useDownload();
    const childComponentRef = ref();

    // breadcrumb
    setNewPageBreadcrumbs(t("reconcile.journal.breadcrumb"), [{ title: t("reconcile.journal.title") }]);

    const form = reactive({
      batch: '',
      channel: '',
      type: '',
      institution_name: '',
      transaction_date: '',
      journal_date: '',
      fqva: '',
      transaction_source: '',
      channel_ref_id: '',
      file_name: '',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    });

    const batchCode = ref();

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      batch: query.value.batch || form.batch,
      institution_name: query.value.institution_name || form.institution_name,
      transaction_date: query.value.transaction_date || form.transaction_date,
      journal_date: query.value.journal_date || form.journal_date,
      fqva: query.value.fqva || form.fqva,
      channel_ref_id: query.value.channel_ref_id || form.channel_ref_id,
      transaction_source: query.value.transaction_source || form.transaction_source,
      file_name: query.value.file_name || form.file_name,
      channel: query.value.channel || form.channel,
      type: query.value.type || form.type,
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useJournalEntry(() => fetchParams.value);

    watch(data, (item) => {
      // let currentBatch  = '';
      // data.value?.data.forEach((element: string[]) => {
      //   if(currentBatch != element['batch']) {
      //     currentBatch = element['batch'];
      //     element['is_header'] = true;
      //   } else {
      //     element['is_header'] = false;
      //   }
      // })
    });

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const handleSubmit = async () => {
      if (hash.value == null || hash.value == '') {
        showErrorPopup({
          title: 'Validation',
          text: 'Channel tidak boleh kosong',
          message: 'Channel tidak boleh kosong',
        })

        return false;
      }

      if (files.value.length == 0 || files.value == null) {
        showErrorPopup({
          title: 'Validation',
          text: 'File tidak boleh kosong',
          message: 'File tidak boleh kosong',
        })

        return false;
      }

      if (!files.value) return false;
      isUploading.value = true;
      const [err] = await to(uploadFile((files.value as any)?.[0].raw));
      isUploading.value = false;

      if (err) {
        showErrorPopup({
          title: err.name,
          text: err.message,
          message: err.message,
        })

        return false;
      }

      showSuccessPopup({
        title: 'Update Status',
        text: 'Success'
      });

      files.value = [];
      hash.value = null;
      childComponentRef.value.clear();

      currentTab.value = 'first'

      refreshList();
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        batch: form.batch,
        institution_name: form.institution_name,
        transaction_date: form.transaction_date,
        journal_date: form.journal_date,
        fqva: form.fqva,
        transaction_source: form.transaction_source,
        file_name: form.file_name,
        channel: form.channel,
        type: form.type,
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const resetExecute = () => {
      batchCode.value = '';
    };

    const payload = {};

    const copyBatch = (code) => {
      window.scrollTo(0, document.body.scrollHeight);
      navigator.clipboard.writeText(code);
      batchCode.value = code;
      ElMessage({ message: 'batch filtered & copied', type: 'success' });
      form.batch = code;

    };

    const execute = async () => {
      dialogVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      ApiService.setHeader();
      ApiService.put(
        `app/reconcile/journal-entry/${batchCode.value}/execute`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Update Status',
            text: 'Success'
          });

          resetExecute();

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {

          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const handleClose = (done: () => void) => {
      done();
    }

    const showDialog = () => {
      if (batchCode.value == null || batchCode.value == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Please add batch value first',
          message: 'Please add batch value first',
        });

        return;
      }

      dialogVisible.value = true;
    }

    const showDialogRemark = (val) => {
      remark.value = val;
      dialogRemarkVisible.value = true;
    }

    const download = () => {
      handleDownloadToFile(`/app/reconcile/journal-entry/sample`, 'journal_entry');
    }

    const downloadExcel = (file) => {
      handleDownloadToFile(`app/reconcile/journal-entry`, file,
        {
          download: true,
          batch: query.value.batch || form.batch,
          institution_name: query.value.institution_name || form.institution_name,
          transaction_date: query.value.transaction_date || form.transaction_date,
          journal_date: query.value.journal_date || form.journal_date,
          fqva: query.value.fqva || form.fqva,
          channel_ref_id: query.value.channel_ref_id || form.channel_ref_id,
          transaction_source: query.value.transaction_source || form.transaction_source,
          file_name: query.value.file_name || form.file_name,
          channel: query.value.channel || form.channel,
          type: query.value.type || form.type,
          order_by: 'created_at',
          order: 'DESC',
        }
      );
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      paginationRef,
      isValidating,
      handleChangePage,
      data,
      files,
      handleSubmit,
      isUploading,
      hash,
      channel,
      currentTab,
      moment,
      form,
      filtered,
      resetExecute,
      batchCode,
      execute,
      submitButton,
      copyBatch,
      handleClose,
      dialogVisible,
      showDialog,
      jsonRespon,
      checkPermission,
      download,
      isDownloading,
      numberFormat,
      childComponentRef,
      dialogRemarkVisible,
      remark,
      showDialogRemark,
      downloadExcel
    };
  },
});
