import useRequest, { useRequestOptions } from "./../useRequest";
import { computed } from "vue";

export type useJournalEntryListParams = {
  date?: string;
  page?: number;
  limit?: number;
};

export interface CommonResponse<T extends any> {
  data: {
    data: {
      data: T;
      rc: string;
      message: string;
      timestamp: string;
      current_page?: number;
      from?: number;
      last_page?: number;
      per_page?: number;
      to?: number;
      total?: number;
    }
    total_amount?: string;
    total_transaction?: number;
  };
}

const useJournalEntryList = (
  getParams: () => useJournalEntryListParams = () => ({ page: 1, limit: 20 }),
  options?: useRequestOptions
) => {
  const { data, ...other } = useRequest<CommonResponse<[]>>(
    "/app/reconcile/journal-entry",
    getParams,
    options
  );
  
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    data: enhancedData,
    ...other
  };
};

export default useJournalEntryList;
