
import { defineComponent, ref } from "vue";
import {
  createElUploadHandler,
  createDeleteFileFromServerFunction
} from "@/core/helpers/upload";
import BaseButton from "@/components/BaseButton.vue";
import { useSizeValidation, useFileTypeValidation } from "./compositions";
import { ElUpload } from "element-plus/lib/el-upload/src/upload.type";

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    showHints: {
      type: Boolean,
      default: true
    },
    maxFileSize: {
      type: Number,
      default: 2000 // 2000 Kb
    },
    fileTypes: {
      type: Array
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    limit: Number,
    multiple: Boolean,
    url: {
      type: String,
      default: "/attachment/upload"
    },
    buttonLabel: {
      type: String,
      default: "Choose File..."
    },
    onError: Function,
    onSuccess: Function,
    onBeforeUpload: Function,
    onUploading: Function
  },
  emits: ["update:modelValue"],
  components: {
    BaseButton
  },
  setup(props, context) {
    const internalFileList = ref(props.modelValue);
    const uploadHTTPRequestFunction = createElUploadHandler(props.url);
    const upload = ref<ElUpload>()

    const updateFileList = (file, newFileList) => {
      internalFileList.value = newFileList;
      context.emit("update:modelValue", internalFileList.value);
    };

    const deleteFileFromServer = createDeleteFileFromServerFunction();
    const handleRemove = (file, newFileList) => {
      updateFileList(file, newFileList);
      if (file.hash) {
        deleteFileFromServer(file.hash);
      }
    };

    const { sizeValidation } = useSizeValidation(props.maxFileSize);
    const { fileTypeValidation } = useFileTypeValidation(
      props.fileTypes as string[]
    );

    const handleBeforeUpload = file => {
      if (props.onBeforeUpload) props.onBeforeUpload(file);
      if (!sizeValidation(file)) return false;
      if (!fileTypeValidation(file)) return false;
      return true;
    };

    const cleanupFileList = file => {
      internalFileList.value = [];
      handleRemove(file, internalFileList.value);
    };

    const clear = () => {
      internalFileList.value = [];
      upload.value?.clearFiles();
    };

    return {
      internalFileList,
      uploadHTTPRequestFunction,
      updateFileList,
      handleRemove,
      handleBeforeUpload,
      cleanupFileList,
      clear,
      upload
    };
  }
});
