import Swal from "sweetalert2";
import { useI18n } from 'vue-i18n';

export const useSizeValidation = (maxFileSize?: number) => {
  const { t } = useI18n()
  const getKiloByte = (fileSize: number) => Math.round(fileSize / 1000);
  const sizeValidation = (file: File) => {
    if (!maxFileSize) return true;
    const kiloByteSize = getKiloByte(file.size);
    if (kiloByteSize > maxFileSize) {
      Swal.fire({
        icon: "error",
        title: t("common.error.popup.title"),
        text: t("common.upload.max.size.hints", {
          size: `${Math.round(maxFileSize / 1000)} Mb`
        }),
        confirmButtonColor: "#F56C6C"
      });
      return false;
    }
    return true;
  };

  return { getKiloByte, sizeValidation }
}

export const useFileTypeValidation = (validFileTypes?: string[]) => {
  const { t } = useI18n()
  const fileTypeValidation = (file: File) => {
    if (!validFileTypes) return true;
    if (validFileTypes && !file.name) return false;
    const lastDotIndex = file.name.lastIndexOf(".");
    const actualDotIndex = lastDotIndex + 1;
    const extension = file.name.substr(actualDotIndex, actualDotIndex);
    const isValid = validFileTypes.includes(extension);
    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: t("common.error.popup.title"),
        text: t("common.upload.file.types.hints", {
          fileTypes: `${validFileTypes.join(", ")}`
        }),
        confirmButtonColor: "#F56C6C"
      });
      return false;
    }
    return true;
  };

  return { fileTypeValidation }
}
