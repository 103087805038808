
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import useJournalEntryType from "@/core/services/compositions/enum/useJournalEntryType";

interface Filter {
  batch: string;
  bcn: string;
  institution_name: string;
  transaction_date: string;
  journal_date: string;
  fqva: string;
  channel_ref_id: string;
  transaction_source: string;
  file_name: string;
  channel: string;
  type: string;
}

export default defineComponent({
  name: "filter-invocation",
  components: {},
  setup(props, context) {
    const { query } = useQuery();
    const { channel } = useChannelByHash();
    const { journalEntryType } = useJournalEntryType();


    const data = reactive<Filter>({
      bcn: query.value.bcn || '',
      batch: query.value.batch || '',
      channel: query.value.channel || '',
      type: query.value.type || '',
      transaction_date: query.value.transaction_date || '',
      journal_date: query.value.journal_date || '',
      fqva: query.value.fqva || '',
      channel_ref_id: query.value.channel_ref_id || '',
      transaction_source: query.value.transaction_source || '',
      file_name: query.value.file_name || '',
      institution_name: query.value.institution_name || '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.bcn = '';
      data.batch = '';
      data.channel = '';
      data.channel_ref_id = '';
      data.type = '';
      data.institution_name = '';
      data.transaction_date = '';
      data.journal_date = '';
      data.fqva = '';
      data.transaction_source = '';
      data.transaction_source = '';
      data.file_name = '';
      data.institution_name = '';
    };

    return {
      data,
      filter,
      reset,
      channel,
      journalEntryType
    };
  }
});
