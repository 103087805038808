import ApiService from "@/core/services/ApiService";
import to from "await-to-js";
import { ElUploadRequestOptions } from "element-plus/lib/el-upload/src/upload.type";

const invokeWhenNeeded = (maybeFunction: any) => {
  if (typeof maybeFunction !== "function") return maybeFunction;
  return invokeWhenNeeded(maybeFunction());
};

type UrlStringOrFunction = (() => string) | string;

export const createUploaderFunction = (
  url: UrlStringOrFunction,
  keyname: string,
  params: {}
) => (file: File, onProgress?: (event: ProgressEvent) => void) => {
  const validUrl = invokeWhenNeeded(url);
  const formData = new FormData();
  
  formData.append(keyname, file);

  for (const key in params) {
    formData.append(key, params[key].value);
  }

  ApiService.setHeader();
  return ApiService.vueInstance.axios({
    method: "POST",
    url: validUrl,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData,
    onUploadProgress: onProgress
  });
};

const multipleFilesUploader = (
  uploaderFunction: (file: File) => Promise<any>
) => async (files: File[], onProgress?: (progress: string) => void) => {
  const total = files.length;
  let currentUploaded = 0;

  const allPromises = files.map(async file => {
    const [err, res] = await to(uploaderFunction(file));
    currentUploaded++;
    if (onProgress) {
      const progressPercentage = (currentUploaded / total) * 100;
      onProgress(`${progressPercentage.toFixed(2)}%`);
    }
    return {
      error: err ? true : false,
      res: res || err
    };
  });

  const [, res] = await to(Promise.all(allPromises));
  return res;
};

export const createMultipleFilesUploaderFunction = (url: string) => {
  const handleUpload = createUploaderFunction(url, 'attachment', {});
  const doMultipleUpload = multipleFilesUploader(handleUpload);
  return doMultipleUpload;
};

export const createElUploadHandler = (
  url: UrlStringOrFunction,
  mappingResponse = (res: any) => res.data.data
) => {
  const handleUpload = createUploaderFunction(url, 'attachment', {});
  return (params: ElUploadRequestOptions) => {
    return handleUpload(params.file, (e: any) => {
      e.percent = parseInt(((e.loaded / e.total) * 100 - 5).toString());
      params.onProgress(e);
    }).then(mappingResponse);
  };
};

export const elUploadMergeNewFiles = (elUploadFileList: any[]) => {
  const existingFiles = elUploadFileList
    .filter(item => !item.response)
    .map(item => item.id);
  const newFiles = elUploadFileList
    .filter(item => item.response)
    .map(item => item.response.id);
  return [...existingFiles, ...newFiles];
};

export const createDeleteFileFromServerFunction = (
  url = "/attachment/delete/"
) => (hash: string) => {
  ApiService.setHeader();
  return ApiService.vueInstance.axios({
    method: "DELETE",
    url: url + hash
  });
};
